<template>
  <v-dialog 
    :value="value"
    width="600"
    @click:outside="cancel"
  >
    <v-card>
      <v-card-title>Copy Promotion</v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="promoClone.promo_name"
                  label="Promotion Name"
                  hide-details="auto"
                  :rules="[v => !!v || 'Promotion Name is required', validateText]"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="promoClone.promo_number"
                  label="Promotion Number"
                  hide-details="auto"
                  :rules="[promoNumberValidation]"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="promoClone.promo_category"
                  label="Promotion Category"
                  :menu-props="{ offsetY: true }"
                  :items="validCloneTo"
                  :rules="[v => !!v || 'Promotion Category is required']"
                  item-text="name"
                  hide-details="auto"
                  return-object
                  @change="validateDates"
                  outlined
                  dense>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-2 py-0">
                <PromoDates
                  ref="promoDates"
                  :promo="promoClone"
                  hide-details="auto"
                  :readOnlyPromo="false">
                </PromoDates>
              </v-col>
            </v-row>
            <v-row v-if="isScanToComplex">
              <v-col>
                <v-text-field
                  v-model="promoClone.buy_x"
                  label="Buy X"
                  type="number"
                  min="1"
                  step="1"
                  hide-details="auto"
                  :rules="rules"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="promoClone.save_y"
                  label="Save $Y"
                  type="number"
                  min="0.01"
                  step=".01"
                  prefix="$"
                  hide-details="auto"
                  :rules="[...rules, isValidPrice]"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="promoClone.promo_category">
                <v-select
                  label="Promotion Type"
                  item-value="id"
                  item-text="name"
                  :items="validCloneBillingModes"
                  v-model="promoClone.billing_mode"
                  background-color="#fff"
                  :rules="[v => !!v || 'Promotion Type is required']"
                  dense
                  hide-details="auto"
                  validate-on-blur
                  :menu-props="{ offsetY: true }"
                  outlined>
                </v-select>
              </v-col>
            <template v-if="showAllowanceFields">
              <v-col
                v-for="(field, i) in currentAllowanceFields"
                :key="i">
                <v-text-field
                  v-model="promoClone[`${field.value}`]"
                  :label="field.name"
                  type="number"
                  min="0.00"
                  step=".01"
                  prefix="$"
                  style="max-width: 254px"
                  hide-details="auto"
                  :rules="[isValidAllowancePrice]"
                  :error="showErrorState(field, promoClone)"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </template>
            </v-row>
            <v-row v-if="!promo.promotion_price">
              <v-col>
                <v-text-field
                  v-model="promoClone.promotion_price"
                  label="Promotion Price"
                  type="number"
                  min="0.00"
                  step=".01"
                  prefix="$"
                  style="max-width: 254px"
                  hide-details="auto"
                  :rules="[isValidRequiredPrice]"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider class="mt-8"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="cancel"
          text
          color="grey darken-2">
          Cancel
        </v-btn>
        <v-btn
          :loading="loading"
          color="green darken-2"
          class="white--text"
          @click="clonePromo">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// API
import Promos from '@/axios/promotion-endpoint'
// Mixins
import { displayAlert } from '@/mixins/alert'
import { formValidation } from '@/mixins/form-validation'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
// Components
import PromoDates from '@/components/PromoDates.vue'
export default {
  data () {
    return {
      loading: false,
      valid: true,
      promoClone: {
        promo_name: null,
        promo_number: null,
        promo_category: null,
        start_dt: null,
        end_dt: null,
        buy_x: null,
        save_y: null,
        billback_allowance: null,
        amap_allowance: null,
        early_buy_allowance: null,
        rebate_amount: null,
        billing_mode: null,
        promotion_price: null
      },
      rules: [v => !!v || 'Required field'],
      scanToComplex: {
        cloneFrom: ['AMOUNT_OFF', 'PERCENT_OFF'],
        cloneTo: ['BUY_X_SAVE_$Y']
      },
      scanToBillback: {
        cloneFrom: ['AMOUNT_OFF'],
        cloneTo: ['BILLBACK']
      },
      billbackToScan: {
        cloneFrom: ['BILLBACK'],
        cloneTo: ['AMOUNT_OFF']
      },
      allowanceFields: [
        { name: 'Billback Allowance', value: 'billback_allowance' },
        { name: 'Scan Allowance', value: 'rebate_amount' },
        { name: 'AMAP Allowance', value: 'amap_allowance' }
      ],
      billbackError: false,
      scanError: false
    }
  },
  name: 'CloneModal',
  components: { PromoDates },
  mixins: [displayAlert, formValidation, userAccess, utils],
  props: {
    value: Boolean,
    promo: Object
  },
  watch: {
    'promoClone.promo_category': {
      handler (newValue, oldValue) {
        if ((oldValue || this.isAMAP) && newValue?.valid_billing_modes?.length > 0) {
          const validModes = this.validCloneBillingModes
          if (validModes.length === 1) {
            this.promoClone.billing_mode = validModes[0].id
          } else {
            this.promoClone.billing_mode = null
          }
        }
      },
      deep: true
    },
    isScanToComplex: {
      handler (newValue) {
        if (newValue === false) {
          this.promoClone.buy_x = null
          this.promoClone.save_y = null
        }
      }
    },
    isScanToBillback: {
      handler (newValue) {
        if (newValue === false) {
          this.promoClone.billback_allowance = null
          this.resetForm()
        }
      }
    },
    isBillbackToScan: {
      handler (newValue) {
        const { billback_allowance } = this.promo
        if (newValue === false) {
          this.promoClone.billback_allowance = billback_allowance
          this.promoClone.rebate_amount = null
        } else {
          this.promoClone.billback_allowance = null
          this.promoClone.rebate_amount = null
        }
        this.resetForm()
      }
    }
  },
  async created () {
    if (this.promoCategories.length === 0) {
      await this.$store.dispatch('getPromoCategories')
    }
    this.initPromoFields()
  },
  computed: {
    promoCategories () {
      return this.sortByKey(this.userPromoCategories, 'name')
    },
    isAMAPtoAMAPEnabled () {
      return (this.tenant === 'alliance-retail-group' && this.isAdmin && this.isAMAP)
    },
    billing_modes () {
      if (this.isAMAPtoAMAPEnabled) {
        return this.$store.getters.billing_modes
      }
      return this.$store.getters.billing_modes.filter(mode => mode?.constant !== 'AMAP')
    },
    validCloneBillingModes () {
      const validIds = this.promoClone.promo_category?.valid_billing_modes || []
      if (validIds.length > 0) {
        return this.billing_modes.filter(mode => validIds.includes(mode?.id))
      }
      return this.billing_modes
    },
    currentAllowanceFields () {
      let fields = []
      if (this.isAMAPtoAMAP) {
        fields = ['amap_allowance']
      } else if (this.cloneToType === 'BILLBACK') {
        fields = ['billback_allowance']
      } else if (this.cloneToType === 'AMOUNT_OFF') {
        fields = ['rebate_amount']
      }
      return this.allowanceFields.filter(field => {
        return fields.includes(field.value)
      })
    },
    showAllowanceFields () {
      return (this.cloneToCategory?.billback_promotion && !this.cloneToNonAllowance)
        || (this.isScanToBillback || this.isBillbackToScan)
        || (this.isNonAllowanceToAllowance)
    },
    cloneFromCategory () {
      return this.promo?.promo_category
    },
    cloneToCategory () {
      return this.promoClone.promo_category
    },
    cloneFromType () {
      return this.cloneFromCategory?.promo_type?.constant
    },
    cloneToType () {
      return this.cloneToCategory?.promo_type?.constant
    },
    validTypes () {
      if (this.promo) {
        const validCrossTypes = this.getValidCrossCategoryTypes(this.cloneFromType)
        return [this.cloneFromType, ...validCrossTypes]
      }
      return []
    },
    validCloneTo () {
      let valid = this.promoCategories.filter(cat => this.validTypes.includes(cat.promo_type.constant)) 
      if (this.isAMAP){
        return valid
      }
      const specialFourWeekIndex = valid.findIndex(cat => cat.name === 'Special 4 Week')
      if (specialFourWeekIndex > -1) valid.push(valid.splice(specialFourWeekIndex, 1)[0])
      return valid.filter(cat => cat.name != "AMAP")
    },
    isCrossCategory () {
      if (this.promo && this.cloneToCategory?.id) {
        return this.cloneFromCategory.id !== this.cloneToCategory.id
      }
      return false
    },
    cloneToNonAllowance () {
      return this.isNonAllowanceCategory(this.cloneToCategory?.name)
    },
    isNonAllowanceToAllowance () {
      return this.isNonAllowanceCategory(this.cloneFromCategory?.name) && !this.cloneToNonAllowance
    },
    isScanToComplex () {
      return (this.scanToComplex.cloneFrom.includes(this.cloneFromType))
        && (this.scanToComplex.cloneTo.includes(this.cloneToType))
    },
    isScanToBillback () {
      return !this.cloneToNonAllowance
        && (this.scanToBillback.cloneFrom.includes(this.cloneFromType))
        && (this.scanToBillback.cloneTo.includes(this.cloneToType))
    },
    isBillbackToScan () {
      return !this.cloneToNonAllowance
        && (this.billbackToScan.cloneFrom.includes(this.cloneFromType))
        && (this.billbackToScan.cloneTo.includes(this.cloneToType))
    },
    isAMAPtoAMAP () {
      return (this.isAMAP && this.cloneToCategory.name === 'AMAP')
    },
    isAMAPtoNonAMAP () {
      return (this.isAMAP && this.cloneToCategory.name !== 'AMAP')
    },
    scanAllowanceRequired () {
      return this.isBillbackToScan || (this.isNonAllowanceToAllowance && this.cloneToCategory.scan_promotion)
    },
    billbackAllowanceRequired () {
      return this.isScanToBillback
        || (this.isNonAllowanceToAllowance && this.cloneToCategory.billback_promotion)
        || (this.isAMAPtoNonAMAP && this.cloneToCategory.billback_promotion)
    },
    validScanClone () {
      return this.scanAllowanceRequired ? this.hasScanAllowance(this.promoClone) : true
    },
    validBillbackClone () {
      return this.billbackAllowanceRequired ? this.hasBillingAllowance(this.promoClone) : true
    },
    validAMAPtoAMAPClone () {
      return this.isAMAPtoAMAP ? this.isValidAllowanceValue(this.promoClone.amap_allowance) : true
    }
  },
  methods: {
    initPromoFields () {
      const {
        name, start_dt, end_dt, promo_category,
        billback_allowance, billing_mode, amap_allowance
      } = this.promo

      const defaultCat = this.validCloneTo.find(c => c.id === promo_category.id)
      this.promoClone.promo_name = name
      this.promoClone.start_dt = start_dt
      this.promoClone.end_dt = end_dt
      this.promoClone.promo_category = defaultCat
      this.promoClone.billing_mode = billing_mode.id

      if (promo_category.billback_promotion) {
        if (this.isAMAPtoAMAPEnabled) {
          this.promoClone.amap_allowance = amap_allowance
        } else {
          this.promoClone.billback_allowance = billback_allowance
        }
      }
      this.initPromoNumber()
    },
    async initPromoNumber() {
      try {
        const res = await Promos.suggestedPromoNumber()
        this.promoClone.promo_number = res?.data?.promo_number
      } catch (err) {
        this.handleError(err)
      }
    },
    async clonePromo () {
      const valid = this.validate()
      if (!valid) return

      this.loading = true
      this.promoClone = this.$config.nullifyEmptyStrings(this.promoClone)
      const payload = { 
        ...this.promoClone,
        category: this.promoClone.promo_category.id,
        promo_start: this.promoClone.start_dt,
        promo_end: this.promoClone.end_dt,
        variance: this.scanAllowanceRequired ? this.tenantDefaultVariance[this.tenant] : this.promo.variance
      }
      try {
        const res = await Promos.clone(this.promo.id, payload)
        if (res.data?.id) {
          this.$emit('success', res.data.id, payload.promo_name) 
          this.resetForm()
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    cancel () {
      this.$emit('cancel')
      this.resetForm()
    },
    validate () {
      const validFields = this.$refs.form.validate()
      const validDateFields = this.$refs.promoDates.validate()
      if (!this.validBillbackClone) {
        this.billbackError = true
        const err = 'Please enter a billback allowance value.'
        this.emitAlert(true, 'warning', err)
        return false
      }
      if (!this.validScanClone) {
        this.scanError = true
        this.emitAlert(true, 'warning', 'Please enter a scan allowance value.')
        return false
      }
      if (!this.validAMAPtoAMAPClone) {
        this.billbackError = true
        const err = 'Please enter an AMAP allowance value.'
        this.emitAlert(true, 'warning', err)
        return false
      }
      return (validFields && validDateFields)
    },
    resetForm () {
      this.$refs.form.resetValidation()
      this.billbackError = false
      this.scanError = false
      this.resetAlert()
    },
    validateDates () {
      this.$refs.promoDates.validateForm()
    },
    promoNumberValidation() {
      const clone_number = this.promoClone.promo_number
      const promo_number = this.promo.promo_number
      if (clone_number && promo_number) {
        if (clone_number === promo_number)
          return 'Promotion number cannot be the same as parent'
      }
      return true
    },
    getValidCrossCategoryTypes (cloneFromType) {
      return [this.scanToComplex, this.scanToBillback, this.billbackToScan].flatMap(config => {
        if (config.cloneFrom.includes(cloneFromType)) {
          return config.cloneTo
        }
        return []
      })
    }
  }
}
</script>